.about-container {
  box-sizing: border-box;
  min-height: calc(100vh - 8rem - 35px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.about-container img {
  box-shadow: 2rem 2rem 2rem black;
  border-radius: 100px;
  width: 200px;
  height: 200px;
  object-fit: cover;
  top: 20px;
  object-position: center 40%;
}

.about-container p {
  color: var(--white-almost);
  font-family: 'Varela Round';
}

.about-container a {
  text-decoration: none;
}

.about-container a:hover {
  text-decoration: underline;
}

.internal-link {
  text-decoration: none;
}
.internal-link:hover {
  text-decoration: underline;
}

span.wave {
  animation-name: wave-animation;
  animation-delay: 1s;
  animation-duration: 2.5s;
  animation-iteration-count: 1;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0%,
  50% {
    transform: rotate(0deg);
  }
  10%,
  30% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  40% {
    transform: rotate(9deg);
  }
}
