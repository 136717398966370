$color-gray-dark: #2a2b2e;
$color-pink-dark: #ff7c7c;
$color-pink-light: #ffb6b9;
$color-blue: #8deaff;
$color-yellow: #ffe084;
$color-white-almost: #f5f5f5;
$color-gray-light: #d9d9d9;
$color-gray-medium: #bdbdbd;

.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.ProjectsCard {
  border: 1px solid $color-white-almost;
  max-width: 700px;
  display: flex;
  padding: 20px 30px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  margin: 25px;
  box-shadow: 10px 10px 20px black;

  a {
    text-decoration: none;
  }

  &-left {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 20px 0;
    align-items: center;
    min-width: 330px;

    img {
      border-radius: 3px;
      max-width: 300px;
      max-height: 170px;
      box-shadow: 0.5rem 0.5rem 0.5rem black;
    }

    &-info {
      display: grid;
      grid-template-columns: 35px 1fr;
      column-gap: 20px;
      padding: 20px 0;

      &-left {
        font-family: 'Ubuntu Mono';
        font-size: 18px;
        color: $color-white-almost;
        display: flex;
        justify-content: flex-end;
      }

      &-right {
        text-align: left;
        font-family: 'Varela Round';
        font-size: 16px;
        max-width: 140px;
      }
    }

    a:hover {
      text-decoration: none;
    }

    &-buttons {
      display: flex;

      &-button {
        width: 60px;
        height: 25px;
        border-radius: 25px;

        border: 1px solid;
        margin: 0 10px;
        transition: text-shadow 100ms;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover,
        &:focus {
          text-shadow: 10px 10px 10px black;
          box-shadow: 5px 5px 10px black;
        }

        span {
          font-style: italic;
          font-size: 14px;
          font-family: 'Ubuntu Mono';
          color: $color-white-almost;
          padding-bottom: 3px;
        }
      }
    }
  }

  &-center {
    height: 300px;
    min-width: 1px;
  }

  &-right {
    display: flex;
    flex-direction: column;
    padding: 20px 0 20px 30px;

    &-header {
      font-size: 30px;
      font-family: 'Ubuntu Mono';
      font-weight: bold;
      color: $color-white-almost;
      text-shadow: 0.5rem 0.5rem 0.5rem black;
    }

    p {
      font-size: 15px;
      color: $color-white-almost;
      text-align: left;
      font-family: 'Varela Round';
    }

    a:hover {
      text-decoration: underline;
    }
  }
}

.ProjectsCardMobile {
  border: 1px solid $color-white-almost;
  max-width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  align-items: center;
  border-radius: 5px;
  margin: 25px;
  box-shadow: 10px 10px 30px black;

  a {
    text-decoration: none;
  }

  &-center {
    width: 200px;
    height: 1px;
    margin: 15px 0;
  }

  &-header {
    font-size: 24px;
    font-family: 'Ubuntu Mono';
    font-weight: bold;
    color: $color-white-almost;
    text-shadow: 0.5rem 0.5rem 0.5rem black;
  }

  p {
    font-size: 15px;
    color: $color-white-almost;
    text-align: left;
    font-family: 'Varela Round';
    margin: 0;
  }

  &-grid {
    display: grid;
    grid-template-columns: 35px 1fr;
    column-gap: 12px;
    padding: 15px 0;

    &-left {
      font-family: 'Ubuntu Mono';
      font-size: 14px;
      color: $color-white-almost;
      display: flex;
      justify-content: flex-end;
    }

    &-right {
      text-align: left;
      font-family: 'Varela Round';
      font-size: 12px;
      max-width: 140px;
    }
  }

  &-buttons {
    display: flex;

    &-button {
      width: 50px;
      height: 20px;
      border-radius: 25px;
      border: 1px solid;
      margin: 0 10px;
      transition: text-shadow 100ms;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover,
      &:focus {
        text-shadow: 10px 10px 10px black;
        box-shadow: 5px 5px 10px black;
      }

      span {
        font-style: italic;
        font-size: 12px;
        font-family: 'Ubuntu Mono';
        color: $color-white-almost;
        padding-bottom: 2px;
      }
    }
  }
}

.DemoCard {
  border: 1px solid $color-white-almost;
  max-width: 700px;
  display: flex;
  padding: 20px 30px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  margin: 25px;
  box-shadow: 10px 10px 20px black;

  .video-container {
    width: 700px;  // 100% width of the .DemoCard
    height: 400px;  // Or adjust to whatever height you desire for the video

    .react-player {
      width: 100% !important;
      height: 100% !important;
    }
  }

  a {
    text-decoration: none;
  }

  &-left {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 20px 0;
    align-items: center;
    min-width: 330px;

    img {
      border-radius: 3px;
      max-width: 300px;
      max-height: 170px;
      box-shadow: 0.5rem 0.5rem 0.5rem black;
    }

    &-info {
      display: grid;
      grid-template-columns: 35px 1fr;
      column-gap: 20px;
      padding: 20px 0;

      &-left {
        font-family: 'Ubuntu Mono';
        font-size: 18px;
        color: $color-white-almost;
        display: flex;
        justify-content: flex-end;
      }

      &-right {
        text-align: left;
        font-family: 'Varela Round';
        font-size: 16px;
        max-width: 140px;
      }
    }

    a:hover {
      text-decoration: none;
    }

    &-buttons {
      display: flex;

      &-button {
        width: 60px;
        height: 25px;
        border-radius: 25px;

        border: 1px solid;
        margin: 0 10px;
        transition: text-shadow 100ms;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover,
        &:focus {
          text-shadow: 10px 10px 10px black;
          box-shadow: 5px 5px 10px black;
        }

        span {
          font-style: italic;
          font-size: 14px;
          font-family: 'Ubuntu Mono';
          color: $color-white-almost;
          padding-bottom: 3px;
        }
      }
    }
  }

  &-center {
    width: 100%;
    height: 1px;
    margin: 15px 0;
  }

  &-right {
    display: flex;
    flex-direction: column;
    padding: 20px 0 20px 30px;

    &-header {
      font-size: 30px;
      font-family: 'Ubuntu Mono';
      font-weight: bold;
      color: $color-white-almost;
      text-shadow: 0.5rem 0.5rem 0.5rem black;
    }

    p {
      font-size: 15px;
      color: $color-white-almost;
      text-align: left;
      font-family: 'Varela Round';
    }

    a:hover {
      text-decoration: underline;
    }
  }
}

.DemoCardMobile {
  border: 1px solid $color-white-almost;
  max-width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  align-items: center;
  border-radius: 5px;
  margin: 25px;
  box-shadow: 10px 10px 30px black;

  .video-container {
    width: 100%;  // 100% width of the .DemoCard
    height: 200px;  // Or adjust to whatever height you desire for the video

    .react-player {
      width: 100% !important;
      height: 100% !important;
    }
  }

  a {
    text-decoration: none;
  }

  &-center {
    width: 200px;
    height: 1px;
    margin: 15px 0;
  }

  &-header {
    font-size: 24px;
    font-family: 'Ubuntu Mono';
    font-weight: bold;
    color: $color-white-almost;
    text-shadow: 0.5rem 0.5rem 0.5rem black;
  }

  p {
    font-size: 15px;
    color: $color-white-almost;
    text-align: left;
    font-family: 'Varela Round';
    margin: 0;
  }

  &-grid {
    display: grid;
    grid-template-columns: 35px 1fr;
    column-gap: 12px;
    padding: 15px 0;

    &-left {
      font-family: 'Ubuntu Mono';
      font-size: 14px;
      color: $color-white-almost;
      display: flex;
      justify-content: flex-end;
    }

    &-right {
      text-align: left;
      font-family: 'Varela Round';
      font-size: 12px;
      max-width: 140px;
    }
  }

  &-buttons {
    display: flex;

    &-button {
      width: 50px;
      height: 20px;
      border-radius: 25px;
      border: 1px solid;
      margin: 0 10px;
      transition: text-shadow 100ms;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover,
      &:focus {
        text-shadow: 10px 10px 10px black;
        box-shadow: 5px 5px 10px black;
      }

      span {
        font-style: italic;
        font-size: 12px;
        font-family: 'Ubuntu Mono';
        color: $color-white-almost;
        padding-bottom: 2px;
      }
    }
  }
}